import validator from 'validator';
import { v4 as uuid } from 'uuid';
import { STORE } from '../../store';
import { emit } from '../../services/socket';
import { renderQuestion } from '../RenderQuestion';
import { httpRequest } from '../../services/network';
import { IQuestion } from '../../interfaces/Question';
import { updateEnvironment } from '../../actions/environment.action';
import { createNewReference, focus, scroll, sleep } from '../../services/shared';
import { newMessage, updateAIThreadId, updateAppointmentMeta, updateTextInput } from '../../actions';
import { actionBasedOnChatResponse, queryAIHandler, saveMessage, updateUUID } from '../../services/saveResponse';
import { formatTimeString, getSimplyBookSlots, makeSimplyBooking } from '../AppointmentHandler';
import { setAttributeValue } from '../Response';

const validateQuestions = ['EMAIL', 'NUMBER', 'NAME', 'APPOINTMENT', 'SMART_QUESTION'];
const excludeAnswers = ['SKIP', 'BACK'];

export const submitAnswer = async (answer: string, original = '', next = null, isMedia = false) => {
  try {
    let aiResult: any = {};
    const state = STORE.getState();
    let activeQuestion = state.environment.activeQuestion;
    if (!answer) {
      return;
    }

    STORE.dispatch(updateTextInput({ value: '', original: '' }));

    const mid = uuid();
    if (localStorage.getItem('agentId')) {
      activeQuestion = null
    }

    let media = [];
    if (isMedia) {
      media.push({
        caption: '',
        type: 'document',
        url: answer
      })
    }

    emit('message', {
      text: answer,
      messageBy: 'user',
      medias: media
    });
    scroll();
    STORE.dispatch(newMessage({
      label: answer,
      position: 'right',
      type: 'STATEMENT',
      mid,
      questionId: activeQuestion?.id,
      isValid: (activeQuestion?.type === 'name') ||
        (activeQuestion?.type === 'email' && validator.isEmail(answer.trim())) ||
        (activeQuestion?.type === 'phone' && isPhoneValid(activeQuestion, answer))
    }));

    saveMessage({
      type: 'message',
      text: answer,
      ...(activeQuestion?.type === 'phone' && { prefix: answer.split(' ')[0] }), // As phone component send value in this format 'dialcode number'
      messagedBy: 'user',
      questionId: activeQuestion?.id,
      shouldNotify: activeQuestion?.sendNotifications || false,
      attributeId: activeQuestion?._attribute || '',
      mid,
      medias: media
    })
      .catch((error) => console.log(error));
    scroll();

    // Action based on chat response
    actionBasedOnChatResponse(activeQuestion, answer, state.environment._chatWindowUser)
    actionBasedOnChatResponse(activeQuestion, answer, state.environment._chatWindowUser, state.environment._user)
      .catch(error => console.log(error));

    // STORE.dispatch(updateMessage({}));
    STORE.dispatch(updateTextInput({
      status: state.environment.liveChat || state.environment.enableTextInput || state.text.status,
      value: '',
      original: ''
    }));

    if (!activeQuestion) {
      return;
    }

    if (activeQuestion.type === 'button' && state.appointmentMeta.isActive) {
      STORE.dispatch(updateEnvironment({ typing: true }));
      const messageUuid = uuid();
      const appointmentMeta = state.appointmentMeta;

      if (
        !appointmentMeta.selectedValues.date &&
        !appointmentMeta.selectedValues.slot
      ) {
        const isMore = answer === activeQuestion?.appointment?.moreOption || answer === 'More Option';

        if (isMore) {

          // Get remaining dates
          let optionsPayload: any = state.appointmentMeta.dates.map((date: any) => ({
            id: uuid(), type: 'button', value: String(date), next: { type: 'button', target: 'slot-booking' }
          }));

          const remainingDates = optionsPayload.slice(state.appointmentMeta.datesSent);
          optionsPayload = optionsPayload.slice(state.appointmentMeta.datesSent, state.appointmentMeta.datesSent + 9);

          if (remainingDates.length >= 10) {
            optionsPayload.push({
              id: uuid(), type: 'button', value: activeQuestion.appointment?.moreOption || 'More Option',
              next: { type: 'appointment', target: 'more-appointment-options' }
            });
          }
          STORE.dispatch(updateAppointmentMeta({
            ...state.appointmentMeta, datesSent: state.appointmentMeta.datesSent + optionsPayload.length - 1
          }));

          emit('message', {
            text: activeQuestion.label,
            messageBy: 'bot'
          });

          STORE.dispatch(newMessage({
            label: activeQuestion.label,
            position: 'left',
            type: 'BUTTON',
            mid: messageUuid,
            options: optionsPayload
          }));

          saveMessage({
            type: 'button',
            text: activeQuestion.label,
            messagedBy: 'bot',
            mid: messageUuid
          }).then().catch();

          scroll();
          focus();

          STORE.dispatch(updateEnvironment({ typing: false }));
          return;

        }

        sleep(2);

        let response: any = await getSimplyBookSlots(answer);

        if (!response.status) {

          const messageText = activeQuestion?.message?.unavailable || 'Sorry, we are unable to process your request at this time. Please try again later.';
          emit('message', {
            text: messageText,
            messageBy: 'bot'
          });

          STORE.dispatch(newMessage({
            label: messageText,
            position: 'left',
            type: 'STATEMENT',
            mid: messageUuid,
            options: []
          }));
          saveMessage({
            type: 'message',
            text: messageText,
            messagedBy: 'bot',
            mid: messageUuid
          }).then().catch();
          scroll();
          focus();
          STORE.dispatch(updateEnvironment({ typing: false }));
          return;
        }
        const duration: any = response.duration;
        const slots: any = response.slots;

        const timeSlots = slots.map((slot: any) => {
          const startTime = new Date(slot.id);
          const endTime = new Date(startTime.getTime() + duration * 60000);

          return {
            id: slot.id,
            start: formatTimeString(startTime),
            end: formatTimeString(endTime)
          };
        });

        let optionsPayload = timeSlots.map((slot: any) => ({
          id: uuid(), type: 'button', value: `${slot.start}-${slot.end}`, next: { type: 'button', target: 'slot-booking' }
        }));

        const remainingSlots = optionsPayload.slice(state.appointmentMeta.slotsSent);
        optionsPayload = optionsPayload.slice(state.appointmentMeta.slotsSent, state.appointmentMeta.slotsSent + 9);

        if (remainingSlots.length >= 10) {
          optionsPayload.push({
            id: uuid(), type: 'button', value: activeQuestion.appointment?.moreOption || 'More Option',
            next: { type: 'appointment', target: 'more-appointment-options' }
          });
        }
        STORE.dispatch(updateAppointmentMeta({
          ...state.appointmentMeta, slotsSent: state.appointmentMeta.slotsSent + optionsPayload.length - 1,
          slots: timeSlots,
          selectedValues: {
            ...state.appointmentMeta.selectedValues,
            date: answer
          }
        }));


        emit('message', {
          text: activeQuestion?.appointment?.secondaryLabel || activeQuestion.label,
          messageBy: 'bot'
        });

        STORE.dispatch(newMessage({
          label: activeQuestion?.appointment?.secondaryLabel || activeQuestion.label,
          position: 'left',
          type: 'BUTTON',
          mid: messageUuid,
          options: optionsPayload
        }));
        saveMessage({
          type: 'button',
          text: activeQuestion?.appointment?.secondaryLabel || activeQuestion.label,
          messagedBy: 'bot',
          mid: messageUuid
        }).then().catch();
        scroll();
        focus();
        STORE.dispatch(updateEnvironment({ typing: false }));
        return;

      } else if (
        appointmentMeta.selectedValues.date
      ) {
        const isMore = answer === activeQuestion?.appointment?.moreOption || answer === 'More Option';

        if (isMore) {
          // Get remaining slots
          let optionsPayload = state.appointmentMeta.slots.map((slot: any) => ({
            id: uuid(), type: 'button', value: `${slot.start}-${slot.end}`, next: { type: 'button', target: 'slot-booking' }
          }));

          const remainingSlots = optionsPayload.slice(state.appointmentMeta.slotsSent);
          optionsPayload = optionsPayload.slice(state.appointmentMeta.slotsSent, state.appointmentMeta.slotsSent + 9);

          if (remainingSlots.length >= 10) {
            optionsPayload.push({
              id: uuid(), type: 'button', value: activeQuestion.appointment?.moreOption || 'More Option',
              next: { type: 'appointment', target: 'more-appointment-options' }
            });
          }
          STORE.dispatch(updateAppointmentMeta({
            ...state.appointmentMeta, slotsSent: state.appointmentMeta.slotsSent + optionsPayload.length - 1
          }));


          emit('message', {
            text: activeQuestion.label,
            messageBy: 'bot'
          });

          STORE.dispatch(newMessage({
            label: activeQuestion.label,
            position: 'left',
            type: 'BUTTON',
            mid: messageUuid,
            options: optionsPayload
          }));
          saveMessage({
            type: 'button',
            text: activeQuestion.label,
            messagedBy: 'bot',
            mid: messageUuid
          }).then().catch();
          scroll();
          focus();
          STORE.dispatch(updateEnvironment({ typing: false }));
          return;

        } else {

          const startTime = answer.split('-')[0];
          const selectedSlot = state.appointmentMeta.slots.find((s: { start: string }) => s.start === startTime);

          // TODO: Handle error case
          await makeSimplyBooking(selectedSlot.id);
          STORE.dispatch(updateEnvironment({ typing: false }));
          STORE.dispatch(updateAppointmentMeta({
            dates: [],
            datesSent: 0,
            slots: [],
            slotsSent: 0,
            selectedValues: {
              date: '',
              slot: ''
            },
            lastQuestionSent: {},
            isActive: false
          }));
        }
      }

    }
    setAttributeValue(activeQuestion, answer, state?.environment?.customAttributesList || []);
    if (validateQuestions.includes(activeQuestion.type.toUpperCase()) && !excludeAnswers.includes(answer.toUpperCase())) {
      STORE.dispatch(updateTextInput({
        value: '', original: ''
      }));
      await sleep(0.5);
      const messageUuid = uuid();

      if (!state.environment.liveChat) {
        switch (activeQuestion.type.toUpperCase()) {
          case 'EMAIL': {
            /**
             * Logic for Whether to Include or Exclude the domains in selecteddomainsList[] 
             */

            const questionData = { ...activeQuestion.message.configureDomains };
            let query;
            (questionData?.domainAction === 'include' && questionData?.selectedDomainsList?.length)
              ? query = (!(questionData?.selectedDomainsList || []).includes(answer.substr(answer.lastIndexOf('@') + 1).trim()))
              : query = ((questionData?.selectedDomainsList || []).includes(answer.substr(answer.lastIndexOf('@') + 1).trim()));

            if (query && validator.isEmail(answer.trim())) {

              STORE.dispatch(newMessage({
                label: questionData.domainErrorMessage || 'This domain is not acceptable',
                position: 'left',
                type: 'STATEMENT',
                mid: messageUuid,
                isErrorMessage: true
              }));
              emit('message', {
                text: questionData.domainErrorMessage || 'This domain is not acceptable',
                messageBy: 'bot'
              });
              saveMessage({
                type: 'message',
                text: questionData.domainErrorMessage || 'This domain is not acceptable',
                messagedBy: 'bot',
                mid: messageUuid
              }).then().catch();
              scroll();
              focus();
              return;
            }
            if (validator.isEmail(answer.trim())) {
              updateUUID({
                email: answer,
                lead: true
              }).then().catch();
              STORE.dispatch(updateEnvironment({
                response: {
                  ...state.environment.response,
                  email: answer
                }
              }));
              sessionStorage.setItem('attendeeEmail', answer);
              break;
            }

            STORE.dispatch(newMessage({
              label: activeQuestion.message.error,
              position: 'left',
              type: 'STATEMENT',
              mid: messageUuid,
              isErrorMessage: true
            }));
            emit('message', {
              text: activeQuestion.message.error,
              messageBy: 'bot'
            });
            saveMessage({
              type: 'message',
              text: activeQuestion.message.error,
              messagedBy: 'bot',
              mid: messageUuid
            }).then().catch();
            scroll();
            focus();
            return;
          }
          case 'NUMBER': {
            if (validator.isNumeric(answer.trim())) {
              break;
            }
            emit('message', {
              text: activeQuestion.message.error,
              messageBy: 'bot',
              isErrorMessage: true
            });

            STORE.dispatch(newMessage({
              label: activeQuestion.message.error,
              position: 'left',
              type: 'STATEMENT',
              mid: messageUuid,
              isErrorMessage: true
            }));
            saveMessage({
              type: 'message',
              text: activeQuestion.message.error,
              messagedBy: 'bot',
              mid: messageUuid
            }).then().catch();
            scroll();
            focus();
            return;
          }
          case 'NAME': {
            if (answer.trim().length >= activeQuestion.maxRange) {
              emit('message', {
                text: activeQuestion.message.error,
                messageBy: 'bot'
              });

              STORE.dispatch(newMessage({
                label: activeQuestion.message.error,
                position: 'left',
                type: 'STATEMENT',
                mid: messageUuid,
                isErrorMessage: true
              }));
              saveMessage({
                type: 'message',
                text: activeQuestion.message.error,
                messagedBy: 'bot',
                mid: messageUuid,
                isErrorMessage: true
              }).then().catch();
              STORE.dispatch(updateTextInput({
                status: true,
                value: '', original: ''
              }));
              scroll();
              focus();
              return;
            }
            updateUUID({
              name: answer
            }).then().catch();
            STORE.dispatch(updateEnvironment({
              response: {
                ...state.environment.response,
                name: answer
              }
            }));
            sessionStorage.setItem('name', answer);
            break;
          }
          case 'APPOINTMENT': {

            if (!state.environment.preview && sessionStorage.getItem('attendeeEmail') && validator.isEmail(sessionStorage.getItem('attendeeEmail') || '')) {

              await httpRequest('POST', 'process-appointment', {
                email: sessionStorage.getItem('attendeeEmail'),
                _user: state.environment._user,
                _bot: state.environment._id,
                start: new Date(answer).toISOString(),
                end: new Date(new Date(answer).setMinutes(new Date(answer).getMinutes() + activeQuestion.appointment.interval)).toISOString()
              });
            }
            break;

          }
          case 'SMART_QUESTION': {
            let found = false;
            let options = createNewReference(activeQuestion.options);
            options = options.sort((a: any, b: any) => (a.keywordType.toLowerCase() === 'contain') ? 1 : -1);
            let index: any;

            for (const option of options) {
              if (option.keywordType.toLowerCase() === 'exact' && option.smartKeywords.includes(answer)) {
                found = true;

                if (option.next.target === 'end') {
                  STORE.dispatch(updateTextInput({
                    status: false
                  }));
                  return false;
                }

                index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === option.next.target);
                renderQuestion(index);
                scroll();
                focus();
                return;
              } else if (option.keywordType.toLowerCase() === 'contain') {
                for (const keyword of option.smartKeywords) {
                  if (answer.toLowerCase().includes(keyword.toLowerCase()) && !found) {
                    found = true;

                    if (option.next.target === 'end') {
                      STORE.dispatch(updateTextInput({
                        status: false
                      }));
                      return false;
                    }

                    index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === option.next.target);
                    renderQuestion(index);
                    return;
                  }
                }
              }
            }

            if (found) {
              return;
            }

            if (activeQuestion.next.target) {
              if (activeQuestion.next.target === 'end') {
                STORE.dispatch(updateTextInput({
                  status: false
                }));
                return false;
              }
              index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion.next.target);
              renderQuestion(index);
              return;
            }

            break;
          }
          default: {
            scroll();
            focus();
            return;
          }
        }
        scroll();
      }
    }

    STORE.dispatch(updateEnvironment({
      refresh: state.environment.liveChat,
      lastQuestion: activeQuestion?.id,
      skip: false,
      back: false
    }));

    if (activeQuestion?.includeInLeads) {
      updateUUID({
        lead: true,
        customParameters: {
          [activeQuestion.label]: answer
        }
      }).then().catch();
    }

    scroll();
    focus();
    let index;
    if (next) {
      index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === next);
      if (index === -1) {
        index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion?.id);
        index = index + 1;
      }
    } else {
      if (activeQuestion.next.target) {
        index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion.next.target);
        if (index === -1) {
          index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion?.id);
          index = index + 1;
        }
      } else {
        index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion?.id);
        index = index + 1;
      }
    }

    const constants = ['STATEMENT', 'IMAGE', 'CONTACT', 'VIDEO'];
    if (!constants.includes(activeQuestion.label.toUpperCase())) {
      if (!localStorage.getItem('questions')) {
        localStorage.setItem('questions', JSON.stringify([]));
      }
      if (localStorage.getItem('questions')) {
        const qs = JSON.parse(localStorage.getItem('questions') || '');
        localStorage.setItem('questions', JSON.stringify([...qs, {
          question: activeQuestion.label,
          questionId: activeQuestion?.id,
          answer
        }]));
        emit('update-user-details', {
          messages: [...qs, {
            question: activeQuestion.label,
            questionId: activeQuestion?.id,
            answer
          }]
        });
      }
    }

    if (activeQuestion.type.toUpperCase() === 'AI') {
      STORE.dispatch(updateTextInput({
        status: false,
        value: '', original: ''
      }));

      STORE.dispatch(updateEnvironment({
        typing: true
      }));

    } else {
      STORE.dispatch(updateTextInput({
        status: true,
        value: '', original: ''
      }));
    }

    if (activeQuestion.next.target === 'end' || next === 'end') {
      STORE.dispatch(updateTextInput({
        status: false
      }));
      return false;
    }


    if (activeQuestion.type.toUpperCase() === 'AI') {
      aiResult = await queryAIHandler(
        {
          query: answer, _bot: state.environment._id, hash: 'upbu8kseWBhofnorUuICXTmX5',
          userDetails: { _agency: state.environment._agency, _user: state.environment._user, isKeyConfigured: state.ai.isKeyActive },
          ...(state.ai?.threadId && { threadId: state?.ai?.threadId })
        });

      if (aiResult?.threadId) {
        updateUUID({ threadId: aiResult.threadId })
          .then()
          .catch();

        STORE.dispatch(updateAIThreadId({
          threadId: aiResult.threadId
        }));
      }
    }

    if (!state.environment.liveChat) {
      renderQuestion(index, aiResult?.aiResponse);
    }
  } catch (error) {
    console.log(error);
  }
}


// FIXME: These type of handlers should be declared in separate files rather than a common utility to make use of lazy loading properly. WIll discuss in person
export const isPhoneValid = (activeQuestion: IQuestion | any, answer: string) => {
  const minLength = activeQuestion?.phoneValidation?.minLength ?? 7;
  const maxLength = activeQuestion?.phoneValidation?.maxLength ?? 15;
  const isPhoneValidationEnabled = activeQuestion?.phoneValidation?.isEnabled ?? true;

  if (isPhoneValidationEnabled) {
    return validator.isMobilePhone(answer);
  }

  return (answer.length >= minLength && answer.length <= maxLength);
};
