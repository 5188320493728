import { designReducer } from './design.reducer';
import { flowReducer } from './flow.reducer';
import { configurationReducer } from './configuration.reducer';
import { environmentReducer } from './environment.reducer';
import { messageReducer } from './message.reducer';
import { agentReducer } from './agent.reducer';
import { textInputReducer } from './text.reducer';
import { metaReducer } from './meta.reducer';
import { aiReducer } from './ai.reducer';
import { appointmentMetaReducer } from './appointment.reducer';
import { attributeReducer } from './attribute.reducer';
import { combineReducers } from 'redux';
import { botHandlerReducer } from './botHandler';
import { ratingReducer } from './rating.reducer';
import { knowledgeBaseReducer } from './knowledgeBase.reducer';
import { footerReducer } from './footerTabs';

const reducer = combineReducers({
  design: designReducer,
  flows: flowReducer,
  configuration: configurationReducer,
  environment: environmentReducer,
  messages: messageReducer,
  agents: agentReducer,
  text: textInputReducer,
  meta: metaReducer,
  ai: aiReducer,
  appointmentMeta: appointmentMetaReducer,
  attributes: attributeReducer,
  botHandler: botHandlerReducer,
  ratingScreen: ratingReducer,
  knowledgeBase: knowledgeBaseReducer,
  footerTab: footerReducer
});

export { reducer };
