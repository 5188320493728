import axios from 'axios';
axios.defaults.responseType = 'json';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const httpRequest = async (
  method: string,
  url: string,
  data?: any,
  headers: any = {
    "Content-Type": "application/json",
  }
) =>
  new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data,
      headers
    })
      .then(response => {
        if (String(response.status).match(/20/gi)) {
          resolve(response.data);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err) => {
        reject(err);
      })
  });


