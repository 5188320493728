import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import ViewHandler from './components/Layout/ViewHandler';


function App() { 
 
  useEffect(() => {
    (window as any).chatAreaRef = React.createRef();
  }, []);

  return (
    <>
      <BrowserRouter>
          <ViewHandler /> 
      </BrowserRouter>
    </>
  );
}

export default App;
