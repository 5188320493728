import { LOAD_APPOINTMENT_META, UPDATE_APPOINTMENT_META } from '../actions/actions';
import { createNewReference } from '../services/shared';

const initialState = {
  dates: [],
  datesSent: 0,
  slots: [],
  slotsSent: 0,
  selectedValues: {
    date: '',
    slot: ''
  },
  lastQuestionSent: {},
  isActive: false
}

const appointmentMetaReducer = (state = initialState, action: any) => {
  if (action.type === UPDATE_APPOINTMENT_META) {
    const updatedAppointmentMeta = createNewReference({ ...state, ...action.payload });    
    return updatedAppointmentMeta;
  }
  if (action.type === LOAD_APPOINTMENT_META) {
    return createNewReference(action.payload);
  }
  return state;
};

export { appointmentMetaReducer };