import { IQuestion } from '../../interfaces/Question';
import { findQuestions, replaceAttributeWithValueInObject } from '../Response';

import { v4 as uuid } from 'uuid';
import { createNewReference, focus, scroll, sleep } from '../../services/shared';
import { STORE } from '../../store';
import { updateEnvironment } from '../../actions/environment.action';
import { newMessage, updateAI, updateAppointmentMeta, updateTextInput } from '../../actions';
import { emit } from '../../services/socket';
import { saveMessage } from '../../services/saveResponse';
import { requestLivechat } from '../LiveChat';
import { validateAiResponse } from '../../services/ai';
import { getFinalAiQuestion, handleInvalidAiResponse } from '../AI';
import { formatDateString, getSimplyBookDates } from '../AppointmentHandler';

const keyboard = ['QUESTION', 'EMAIL', 'PHONE', 'NUMBER', 'LOCATION', 'NAME', 'LIVE_CHAT', 'SMART_QUESTION', 'AI'];

export const renderQuestion = async (index = 0, aiResponse = '', isRestart = false) => {
  try {

    const state: any = STORE.getState();
    let activeQuestion: IQuestion = state.environment.activeQuestion;

    STORE.dispatch(updateEnvironment({
      typing: false,
      refresh: true,
    }));

    STORE.dispatch(updateTextInput({
      status: false
    }));

    let messages: any = await findQuestions(state.flows[0]?.questions, index);

    if (!messages.length) {
      emit('update-user-details', {
        isCompleted: true
      });
    } else {
      if (messages[messages.length - 1].type.toLowerCase() === 'statement') {
        emit('update-user-details', {
          isCompleted: true
        });
      }
    }

    if (activeQuestion?.type === 'AI' && !isRestart) {

      const aiReplies = state.ai.aiReplies || 0;

      let resetAIReplies = false;
      if (aiReplies < (activeQuestion.queriesToHandle || 0)) {

        const { gptAnswer }: any = aiResponse;
        let finalAiQuestion: any;

        const isValidResponse: any = validateAiResponse(aiResponse);

        if (!isValidResponse.valid) {
          let result = handleInvalidAiResponse(isValidResponse);
          finalAiQuestion = await getFinalAiQuestion(activeQuestion, result);

        } else {
          finalAiQuestion = aiResponse
            ? [
              {
                ...activeQuestion,
                label: gptAnswer,
                delay: 0,
                type: 'AI',
                shouldFeedbackVisible: true,
                _id: undefined,
              },
            ]
            : [];
        }
        resetAIReplies = !!aiResponse && aiReplies + 1 >= (activeQuestion.queriesToHandle || 0);
        messages = resetAIReplies ? [...finalAiQuestion, ...messages] : finalAiQuestion;
      }

    }

    if (messages.some((q: IQuestion) => q.type === 'AI' && !!q._id)) {
      STORE.dispatch(updateAI({
        aiReplies: 0
      }));
    } else if (messages.some((q: IQuestion) => q.type === 'AI')) {
      STORE.dispatch(updateAI({
        aiReplies: state.ai.aiReplies + 1
      }));
    }

    const simplyBookIndex = messages.findIndex((m: any) => m.type === 'appointment' && m.appointmentHandler === 'simply-book');


    if (messages[simplyBookIndex]) {

      STORE.dispatch(updateEnvironment({ typing: true }));
      const simplyDates: any = await getSimplyBookDates(messages[simplyBookIndex].maxRange);

      if (!simplyDates.status) {
        const messageText = messages[simplyBookIndex]?.unavailable || 'Sorry, we are unable to process your request at this time. Please try again later.';
        const messageUuid = uuid();

        STORE.dispatch(newMessage({
          label: messageText,
          position: 'left',
          type: 'STATEMENT',
          mid: messageUuid
        }));
        emit('message', {
          text: messageText,
          messageBy: 'bot'
        });
        saveMessage({
          type: 'message',
          text: messageText,
          messagedBy: 'bot',
          mid: messageUuid
        }).then().catch();

        STORE.dispatch(updateEnvironment({
          typing: false,
          refresh: false,
          skip: false,
          back: false
        }));
        scroll();
        focus();
        return;
      } else {

        const filteredDates = simplyDates.response.filter((dateInfo: any) => !dateInfo.is_day_off);
        const simplyAllListedDays = filteredDates.map((dateInfo: any) => formatDateString(dateInfo.date));
        const formattedDates = simplyAllListedDays.map(formatDateString);


        // Make payload for sending available dates
        let optionsPayload = formattedDates.map((date: any) => ({
          id: uuid(), type: 'button', value: String(date), next: { type: 'button', target: 'slot-booking' }
        }));


        const datesSent = state.appointmentMeta.datesSent;
        const remainingOptions = optionsPayload.slice(datesSent);
        optionsPayload = optionsPayload.slice(datesSent, datesSent + 9);

        if (remainingOptions.length >= 10) {
          optionsPayload.push({
            id: uuid(), type: 'button', value: messages[simplyBookIndex].appointment?.moreOption || 'More Option',
            next: { type: 'appointment', target: 'more-appointment-options' }
          });
        }

        STORE.dispatch(updateAppointmentMeta({
          ...state.apointmentMeta, dates: formattedDates, datesSent: datesSent + optionsPayload.length - 1, isActive: true,
          lastQuestionSent: { ...messages[simplyBookIndex], label: messages[simplyBookIndex].label, options: optionsPayload }
        }));

        messages.splice(simplyBookIndex, 1, { ...messages[simplyBookIndex], label: messages[simplyBookIndex].label, options: optionsPayload, type: 'button' })
      }
    }

    if (messages.length) {
      const updateState: any = STORE.getState();
      for await (let message of messages) {

        message = replaceAttributeWithValueInObject(createNewReference(message), [...updateState.attributes]);
        let messageText = message?.label?.replace("{{name}}", sessionStorage.getItem('name') || 'User');


        STORE.dispatch(updateEnvironment({
          typing: true,
          refresh: false,
          skip: false,
          back: false
        }));

        scroll(100);

        await sleep(Math.abs(message.delay));

        const mid = uuid();
        saveMessage({
          type: 'message',
          text: messageText,
          messagedBy: 'bot',
          isFreeAIMessage: aiResponse && !state.ai?.isKeyActive
        })
          .catch(console.log);

        emit('message', {
          text: messageText,
          messageBy: 'bot'
        });

        STORE.dispatch(newMessage({ ...message, label: messageText, mid, createdAt: String(new Date()) }));
        STORE.dispatch(updateEnvironment({
          activeQuestion: message,
          typing: false,
          activeQuestionType: message.type,
          refresh: true,
          skip: message.skip,
          back: message.back,
          liveChat: message.type.toUpperCase() === 'LIVE_CHAT'
        }));
        STORE.dispatch(updateTextInput({
          status: keyboard.includes(message.type.toUpperCase())
        }));

        scroll(100);
        await sleep();
        focus();
        if (message.type.toUpperCase() === 'LIVE_CHAT') {
          requestLivechat();
        }
      }
    }

    // Check if user is interacting with AI component and the user text is considered as a neutral text instead of a greeting or query
    if (!messages.length && activeQuestion?.type === 'AI') {
      STORE.dispatch(updateTextInput({
        status: true
      }));
    }

  } catch (error) {
    console.log(error, "Error in rendering question")
  }
}
