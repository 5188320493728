
/**
* This function will determine if the ai is unsure about the answer or user has used inappropriate language
*/
export const validateAiResponse = (aiResponse: any) => {
   const { gptAnswer, intent, isValidResponse } = aiResponse;
   let res = {};

   if (intent === 'query' && !isValidResponse) {
       res = { valid: false, case: 'unsure', gptAnswer };
   } else if (intent === 'offensive') {
       res = { valid: false, case: 'inappropriate_lang', gptAnswer };
   } else {
       res = { valid: true, case: '', gptAnswer };
   }
   return res;
};


export const handleInAppropriateLangMethod = (messages: any, flows: any) => {
  let aiConfiguredMessages = messages;
  let finalMessage;

  if (
      aiConfiguredMessages?.unusualChatHandling?.inAppropriateLanguage?.showErrorMessage &&
      aiConfiguredMessages?.unusualChatHandling?.inAppropriateLanguage?.stopChatBotResponse
  ) {
      finalMessage = { type: 'end-chat', message: aiConfiguredMessages.profanity };
  } else if (
      aiConfiguredMessages?.unusualChatHandling?.inAppropriateLanguage?.showErrorMessage &&
      aiConfiguredMessages?.unusualChatHandling?.inAppropriateLanguage?.invokeNewMessageInFlow?.enabled
  ) {
      // invoke the configured message in the flow
      const questionId =
          aiConfiguredMessages?.unusualChatHandling?.inAppropriateLanguage?.invokeNewMessageInFlow?.questionId;
          finalMessage = {
          type: 'question',
          message: {
              label: aiConfiguredMessages.profanity,
              next: { type: 'question', target: questionId },
          },
      };
  } else if (aiConfiguredMessages?.unusualChatHandling?.inAppropriateLanguage?.stopChatBotResponse) {
      //handle stop chat bot response
      finalMessage = { type: 'end-chat', message: aiConfiguredMessages.profanity };
  }
  else {
      finalMessage = { type: 'text', message: aiConfiguredMessages.profanity };
  }
  return finalMessage;
};

export const handleUnsureAiResponseMethod = (aiResponse: any, messages: any) => {
  const { gptAnswer } = aiResponse;
  let aiConfiguredMessages = messages;

  let finalQuestion;
  //if user has enabled both error message and live_chat option,showing error message with live chat button
  if (
      aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showErrorMessage &&
      aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showLiveChatButton?.enabled
  ) {
      const errorMessage = aiConfiguredMessages.noAnswer;
      const buttonName = aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showLiveChatButton?.text;
      // final message will be returned like this
      finalQuestion = {
          type: 'button',
          message: {
              type: 'button',
              label: errorMessage,
              options: [
                  {
                      type: 'button',
                      value: buttonName,
                      _id: undefined,
                      next: {
                          type: 'live_chat',
                          target: '',
                      },
                  },
              ],
          },
      };
  } else if (
      aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showErrorMessage &&
      aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.invokeNewMessageInFlow?.enabled
  ) {
      // invoke the configured message in the flow
      const questionId = aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.invokeNewMessageInFlow?.questionId;
      finalQuestion = {
          type: 'question',
          message: {
              label: aiConfiguredMessages.noAnswer,
              next: { type: 'question', target: questionId },
          },
      };
  } else if (aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showLiveChatButton?.enabled) {
      // if user has enabled only for live chat then sending gpt response with live chat option
      const buttonName = aiConfiguredMessages?.unusualChatHandling?.unableToAnswer?.showLiveChatButton?.text;
      // final message will be returned like this
      finalQuestion = {
          type: 'button',
          message: {
              type: 'button',
              label: gptAnswer,
              options: [
                  {
                      type: 'button',
                      value: buttonName,
                      _id: undefined,
                      next: {
                          type: 'live_chat',
                          target: '',
                      },
                  },
              ],
          },
      };
  } else {
      // if user has enabled to show only error message
      finalQuestion = { type: 'text', message: aiConfiguredMessages.noAnswer };
  }

  return finalQuestion;
};