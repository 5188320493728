import { httpRequest } from "./network";

import { Buffer } from 'buffer';
import axios from "axios";
import moment from "moment";

export const scroll = (time = 100) => {
	setTimeout(() => {
		try {
			(window as any).chatAreaRef.current.scrollTo({
				top: (window as any).chatAreaRef.current.scrollHeight,
				behavior: 'smooth'
			});
		} catch (e) {
			console.log(e);
		}
	}, time);
};

export const focus = () => {
	const inputElement1 = document.getElementById('bp-input-1')
	const inputElement2 = document.getElementById('bp-input-2')
	const inputElement3 = document.getElementById('bp-input-3');

	if (inputElement1) {
		inputElement1.focus()
	} else if (inputElement2) {
		inputElement2.focus();
	} else {
		inputElement3?.focus();
	}


}

export const getTime = (num: number) => {
	const hour = Math.floor(num / 60);
	const min = num % 60 === 0 ? '00' : num % 60;
	const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
	const period = hour >= 12 ? 'PM' : 'AM';
	return {
		num: num,
		time: `${formattedHour}:${min} ${period}`
	};
}

export const getTimeSlots = (blockTimes: any, showTimeAsString: any, interval: any, includeStartBlockedTime?: any, includeEndBlockedTime?: any) => {
	let times: any;
	let sums = 60;
	includeStartBlockedTime = includeStartBlockedTime === true;
	includeEndBlockedTime = includeEndBlockedTime === true;
	switch (interval) {
		case 'tenth':
			times = 6;
			sums = 10;
			break;
		case 'quarter':
			times = 4;
			sums = 15;
			break;
		case 'half':
			times = 2;
			sums = 30;
			break;
		case 'one':
			times = 1;
			sums = 60;
			break;
		case 'two':
			times = 1 / 2;
			sums = 120;
			break;
		case 'three':
			times = 1 / 3;
			sums = 180;
			break;
		case 'four':
			times = 1 / 4;
			sums = 240;
			break;
		default:
			times = 1;
			sums = 60;
			break;
	}
	let start = 0;
	let dateTimes = Array(Math.round(24 * times))
		.fill(0)
		.map(function (_) {
			start = start + sums;
			return start;
		});
	blockTimes = Array.isArray(blockTimes) === true && blockTimes.length > 0 ? blockTimes : [];
	if (blockTimes.length > 0) {
		dateTimes = blockTimes.reduce((acc: any, x: any) => {
			return acc
				.filter((y: any) => {
					return includeStartBlockedTime === true ? y <= x[0] : y < x[0];
				})
				.concat(
					acc.filter((y: any) => {
						// eslint-disable-next-line eqeqeq
						return includeEndBlockedTime === true ? y >= x[1] : y > x[1];
					})
				);
		}, dateTimes);
	}
	if (showTimeAsString === true) {
		return dateTimes
			.map(function (x) {
				return getTime(x);
			})
			.reduce((accc: any, element) => {
				accc['' + element.num] = element.time;
				return accc;
			}, {});
	}
	return dateTimes;
}


export const sleep = async (s: number = 1) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(true);
		}, s * 1000);
	});
}

export const uploadMedia = (formData: any) => {
	return httpRequest('POST', 'upload', formData, { 'Content-Type': 'multipart/form-data' });
}

export const getDynamicFont = (fontUrl: string) => {

	axios.get(fontUrl, { responseType: 'arraybuffer' })
		.then((response: any) => {
			// Convert the font data to a base64 data URL
			const fontData = Buffer.from(response.data).toString('base64');
			const fontUrl = `data:application/font-woff2;base64,${fontData}`;

			// Create a style element and add the @font-face rule to it
			const style = document.createElement('style');
			style.appendChild(document.createTextNode(`
                @font-face {
                  font-family: 'custom-font';
                  src: url(${fontUrl}) format('woff2');
                  font-weight: normal;
                  font-style: normal;
                }
              `));

			// Append the style element to the document's head
			document.head.appendChild(style);
		})
		.catch(error => {
			console.error('Font loading error:', error);
		});
}

export const createNewReference = (payload = {}) => {
	try {
		return JSON.parse(JSON.stringify(payload));
	} catch (error) {
		console.log(error, "Error when creating deep copy");
	}
}


export const formatDate = (date: string) => {
	return moment(date).format("MMM D, hh:mmA");
}

export function downloadFile(url: string, filename: string) {
	fetch(url)
		.then(response => response.blob())
		.then(blob => {
			const blobUrl = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = blobUrl;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			URL.revokeObjectURL(blobUrl);
		})
		.catch(error => {
			console.error('Error downloading file:', error);
			// Handle error as needed
		});
}
