import { AI_KEY_ACTIVE, AI_REPLIES, AI_THREAD_ID, AI_INITIALIZE, LOAD_AI_REPLIES } from '../actions/actions';
import { createNewReference } from '../services/shared';

const initialState = { aiReplies: 0, isKeyActive: false, threadId: (window as any).parent?.BotPenguinData?.threadId || '', messages: (window as any).parent.BotPenguinData?.ai?.messages }

const aiReducer = (state = initialState, action: any) => {
  if (action.type === AI_REPLIES) {
    const updatedAiReplies = createNewReference({ ...state, aiReplies: action.payload.aiReplies });    
    return updatedAiReplies;
  }
  if (action.type === AI_KEY_ACTIVE) {
    const updatedAiReplies = createNewReference({ ...state, isKeyActive: true });    
    return updatedAiReplies;
  }
  if (action.type === AI_THREAD_ID) {
    const updatedAiReplies = createNewReference({ ...state, threadId: action.payload.threadId });    
    return updatedAiReplies;
  }
  if (action.type === AI_INITIALIZE) {
    const updatedAiReplies = { ...state, ...action.payload };    
    return updatedAiReplies;
  }
  if (action.type === LOAD_AI_REPLIES) {
    return action.payload;
  }
  return state;
};

export { aiReducer };
